<template>
  <section>
    <div class="cs-height_95 cs-height_lg_95"></div>
    <div class="container">
      <div class="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
        <h3 class="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Simple Steps</h3>
        <h2 class="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Minting Techniques</h2>
      </div>
      <div class="cs-height_50 cs-height_lg_30"></div>
      <div class="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style3 text-center cs-accent_bg">
            <div class="cs-card_in">
              <div class="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">1</div>
              <h3 class="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Connect Your Wallet</h3>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style3 text-center cs-accent_bg">
            <div class="cs-card_in">
              <div class="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">2</div>
              <h3 class="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Select Your Quantity</h3>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style3 text-center cs-accent_bg">
            <div class="cs-card_in">
              <div class="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">3</div>
              <h3 class="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Transection Confirm</h3>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-card cs-style3 text-center cs-accent_bg">
            <div class="cs-card_in">
              <div class="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">4</div>
              <h3 class="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Recive Your NFTs</h3>
            </div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Causes",
  data() {
    return {
    };
  },
  mounted() {},
  computed: {},
};
</script>

<style>
</style>
