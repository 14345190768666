<template>
    <div>
        <section id="main-body">
            <Hero />
            <Services />
            <AboutUs />
            <FunFact />
            <TrendingItem />
            <Causes />
            <Roadmap />
            <Team />
            <Faq />
            <Blog />
            <Cta />
        </section>
    </div>
</template>

<script>
  import Hero from "@/components/Homepage/hero.vue";
  import Services from "@/components/Homepage/services.vue";
  import AboutUs from "@/components/Homepage/aboutus.vue";
  import FunFact from "@/components/Homepage/funFact.vue";
  import TrendingItem from "@/components/Homepage/trendingItem.vue";
  import Causes from "@/components/Homepage/causes.vue";
  import Roadmap from "@/components/Homepage/roadmap.vue";
  import Team from "@/components/Homepage/team.vue";
  import Faq from "@/components/Homepage/faq.vue";
  import Blog from "@/components/Homepage/blog.vue";
  import Cta from "@/components/Homepage/cta.vue";
  export default {
    components: {
        Hero,
        Services,
        AboutUs,
        FunFact,
        TrendingItem,
        Causes,
        Roadmap,
        Team,
        Faq,
        Blog,
        Cta
    },
    mounted() {
    },
    methods:{
    },
  };
</script>
<style scoped>
</style>