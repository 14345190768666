<template>
  <section id="faq">
      <div class="cs-height_95 cs-height_lg_95"></div>
      <div class="container">
        <div class="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
          <h3 class="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">FAQ</h3>
          <h2 class="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Still have questions?</h2>
        </div>
        <div class="cs-height_50 cs-height_lg_30"></div>
        <div class="row cs-accordians cs-style1 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
          <div class="col-lg-8 offset-lg-2">
            <div class="cs-accordian cs-white_bg active" @click="open(0)">
              <div class="cs-accordian_head">
                <h2 class="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q1.</span> How to Mint an NFT?</h2>
                <span class="cs-accordian_toggle"></span>
              </div>
              <div class="cs-accordian-body">
                <p>
                There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.
                </p>
              </div>
            </div><!-- .cs-accordian -->
            <div class="cs-height_25 cs-height_lg_25"></div>
            <div class="cs-accordian cs-white_bg" @click="open(1)">
              <div class="cs-accordian_head">
                <h2 class="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q2.</span> What happens once purchase NFT?</h2>
                <span class="cs-accordian_toggle"></span>
              </div>
              <div class="cs-accordian-body">
                <p>
                There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.
                </p>
              </div>
            </div><!-- .cs-accordian -->
            <div class="cs-height_25 cs-height_lg_25"></div>
            <div class="cs-accordian cs-white_bg" @click="open(2)">
              <div class="cs-accordian_head">
                <h2 class="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q3.</span> How can I obtain NFTs?</h2>
                <span class="cs-accordian_toggle"></span>
              </div>
              <div class="cs-accordian-body">
                <p>
                There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.
                </p>
              </div>
            </div><!-- .cs-accordian -->
            <div class="cs-height_25 cs-height_lg_25"></div>
            <div class="cs-accordian cs-white_bg" @click="open(3)">
              <div class="cs-accordian_head">
                <h2 class="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q4.</span> Do NFTs appreciate in value?</h2>
                <span class="cs-accordian_toggle"></span>
              </div>
              <div class="cs-accordian-body">
                <p>
                There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.
                </p>
              </div>
            </div><!-- .cs-accordian -->
            <div class="cs-height_25 cs-height_lg_25"></div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "Faq",
    components: {
    },
    data() {
      return {
        index: 0
      };
    },
    methods: {
      getSiblings: function (e) {
        // for collecting siblings
        let siblings = []; 
        // if no parent, return no sibling
        if(!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling  = e.parentNode.firstChild;
        
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
      },
      slideUp: function (el, duration=500) {
        const target = el.children[1];
  
        if (target) {
          target.style.transitionProperty = 'height, margin, padding';
          target.style.transitionDuration = duration + 'ms';
          target.style.boxSizing = 'border-box';
          target.style.height = target.offsetHeight + 'px';
          target.offsetHeight;
          target.style.overflow = 'hidden';
          target.style.height = 0;
          target.style.paddingTop = 0;
          target.style.paddingBottom = 0;
          target.style.marginTop = 0;
          target.style.marginBottom = 0;
          window.setTimeout( () => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            //alert("!");
          }, duration);
        }
      },
      slideDown: function (target, duration=500) {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
  
        if (display === 'none')
          display = 'block';
  
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
  
        window.setTimeout( () => {
          target.style.removeProperty('height');
          target.style.removeProperty('overflow');
          target.style.removeProperty('transition-duration');
          target.style.removeProperty('transition-property');
        }, duration);
      },
      open: function(item) {
        if (item == this.index)
          return;

        const newItem = document.getElementsByClassName("cs-accordian")[item];
        const oldItem = document.getElementsByClassName("cs-accordian")[this.index];

        const newBodyItem = newItem.children[1];
        newBodyItem.style.height = "auto"
  
        const siblins = this.getSiblings(newItem);
        for (let i=0; i<siblins.length; i++) {
          this.slideUp(siblins[i]);
        }
  
        this.slideDown(newBodyItem);
  
        newItem.classList.add("active");
        oldItem.classList.remove("active");
  
        this.index = item;
      },
    }
  };
  </script>
  
  <style>
    .cs-accordian-body {
      opacity: 0;
      height: 0px;
      overflow: hidden;
      padding: 0px 20px;
    }
  
    .active .cs-accordian-body {
      opacity: 1;
      height: auto;
    }
  </style>
  