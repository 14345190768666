var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"faq"}},[_c('div',{staticClass:"cs-height_95 cs-height_lg_95"}),_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"cs-height_50 cs-height_lg_30"}),_c('div',{staticClass:"row cs-accordians cs-style1 wow fadeIn",attrs:{"data-wow-duration":"1s","data-wow-delay":"0.2s"}},[_c('div',{staticClass:"col-lg-8 offset-lg-2"},[_c('div',{staticClass:"cs-accordian cs-white_bg active",on:{"click":function($event){return _vm.open(0)}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"cs-height_25 cs-height_lg_25"}),_c('div',{staticClass:"cs-accordian cs-white_bg",on:{"click":function($event){return _vm.open(1)}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"cs-height_25 cs-height_lg_25"}),_c('div',{staticClass:"cs-accordian cs-white_bg",on:{"click":function($event){return _vm.open(2)}}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"cs-height_25 cs-height_lg_25"}),_c('div',{staticClass:"cs-accordian cs-white_bg",on:{"click":function($event){return _vm.open(3)}}},[_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"cs-height_25 cs-height_lg_25"})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp",attrs:{"data-wow-duration":"1s","data-wow-delay":"0.2s"}},[_c('h3',{staticClass:"cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color"},[_vm._v("FAQ")]),_c('h2',{staticClass:"cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm"},[_vm._v("Still have questions?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian_head"},[_c('h2',{staticClass:"cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"},[_c('span',[_vm._v("Q1.")]),_vm._v(" How to Mint an NFT?")]),_c('span',{staticClass:"cs-accordian_toggle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian-body"},[_c('p',[_vm._v(" There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian_head"},[_c('h2',{staticClass:"cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"},[_c('span',[_vm._v("Q2.")]),_vm._v(" What happens once purchase NFT?")]),_c('span',{staticClass:"cs-accordian_toggle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian-body"},[_c('p',[_vm._v(" There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian_head"},[_c('h2',{staticClass:"cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"},[_c('span',[_vm._v("Q3.")]),_vm._v(" How can I obtain NFTs?")]),_c('span',{staticClass:"cs-accordian_toggle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian-body"},[_c('p',[_vm._v(" There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian_head"},[_c('h2',{staticClass:"cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"},[_c('span',[_vm._v("Q4.")]),_vm._v(" Do NFTs appreciate in value?")]),_c('span',{staticClass:"cs-accordian_toggle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-accordian-body"},[_c('p',[_vm._v(" There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. ")])])
}]

export { render, staticRenderFns }