<template>
  <div class="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
    <div class="cs-height_100 cs-height_lg_70"></div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <div class="cs-funfact cs-style1 cs-white_bg text-center">
          <div class="cs-height_60 cs-height_lg_60"></div>
          <div class="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold">
            <span class="odometer">
              <vue-odometer :value="getTotalSupply" animation="smooth"></vue-odometer>
            </span>
          </div>
          <p class="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Total Items</p>
          <div class="cs-height_65 cs-height_lg_65"></div>
        </div>
        <div class="cs-height_25 cs-height_lg_25"></div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <div class="cs-funfact cs-style1 cs-white_bg text-center">
          <div class="cs-height_60 cs-height_lg_60"></div>
          <div class="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold">
            <span class="odometer">
              <vue-odometer :value="getTotalOwners" animation="smooth"></vue-odometer>
            </span>
          </div>
          <p class="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Total Owners</p>
          <div class="cs-height_65 cs-height_lg_65"></div>
        </div>
        <div class="cs-height_25 cs-height_lg_25"></div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <div class="cs-funfact cs-style1 cs-white_bg text-center">
          <div class="cs-height_60 cs-height_lg_60"></div>
          <div class="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold">
            <span class="odometer">
              $<vue-odometer :value="getPriceInUSD" animation="smooth"></vue-odometer>
            </span>
          </div>
          <p class="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Floor Price</p>
          <div class="cs-height_65 cs-height_lg_65"></div>
        </div>
        <div class="cs-height_25 cs-height_lg_25"></div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
        <div class="cs-funfact cs-style1 cs-white_bg text-center">
          <div class="cs-height_60 cs-height_lg_60"></div>
          <div class="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold">
            <span class="odometer">
              $<vue-odometer :value="getVolumeTrades" animation="smooth"></vue-odometer>
            </span>
          </div>
          <p class="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Volume Traded</p>
          <div class="cs-height_65 cs-height_lg_65"></div>
        </div>
        <div class="cs-height_25 cs-height_lg_25"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueOdometer from 'v-odometer/src'
import { BigNumber } from 'bignumber.js'


export default {
  name: "FunFact",
  components: {
    'vue-odometer': VueOdometer
  },
  data() {
    return {
    };
  },
  mounted () {
  },
  computed: {
    getTotalSupply() {
      if(this.$store.state.dce.totalSupply)
        return BigNumber(this.$store.state.dce.totalSupply).toNumber();
      else
        return 0;
    },
    getTotalOwners() {
      if (this.$store.state.dce.total_owners)
        return BigNumber(this.$store.state.dce.total_owners).toNumber();
      else
        return 0;
    },
    getPrice() {
        if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2){                // pending or pubsale
          return BigNumber(this.$store.state.dce.priceInPubsale).shiftedBy(-18);
        }
        else {                                                 // presale
          return BigNumber(this.$store.state.dce.priceInPresale).shiftedBy(-18);   
        }
    },
    getPriceInUSD() {
      let ethPrice;
      if(BigNumber(this.$store.state.ethPrice).isNaN()) {
        ethPrice = 0;
      }
      else {
        ethPrice = this.$store.state.ethPrice;
      }

      if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2){                // pending or pubsale
        return BigNumber(this.$store.state.dce.priceInPubsale)
                  .times(BigNumber(ethPrice))
                  .shiftedBy(-18).decimalPlaces(2, 1).toNumber();
      }
      else {                                                                                      // presale
        return BigNumber(this.$store.state.dce.priceInPresale)
                  .times(BigNumber(ethPrice))
                  .shiftedBy(-18).decimalPlaces(2, 1).toNumber();
      }
    },
    getVolumeTrades() {
      let ethPrice;
      if(BigNumber(this.$store.state.ethPrice).isNaN())
        ethPrice = 0;
      else
        ethPrice = this.$store.state.ethPrice;

      if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2){                // pending or pubsale
          return BigNumber(this.$store.state.dce.priceInPubsale)
                  .times(BigNumber(ethPrice))
                  .times(BigNumber(this.$store.state.dce.totalSupply))
                  .shiftedBy(-18).decimalPlaces(2, 1).toNumber();
      }
      else {                                                 // presale
          return BigNumber(this.$store.state.dce.priceInPresale)
                  .times(BigNumber(ethPrice))
                  .times(BigNumber(this.$store.state.dce.totalSupply))
                  .shiftedBy(-18).decimalPlaces(2, 1).toNumber();  
      }
    },
  },
  methods: {
  },
};
</script>

<style scoped>
</style>