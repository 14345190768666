<template>
    <div class="cs-hero cs-style1 cs-type2 cs-bg text-center  cs-ripple_version" :data-src="require(`@/assets/img/heroBg.png`)" id="home">
        <div class="cs-dark_overlay"></div>
        <div class="container">
            <div class="cs-hero_img wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                <img :src="require(`@/assets/img/hero_img_2.png`)" alt="Hero Image">
                <div class="cs-hero_img_sm">
                    <img :src="require(`@/assets/img/hero_img_sm.png`)" alt="Hero Image">
                </div>
            </div>
            <!-- <img src="@/assets/img/hero-over.png" class="img-fluid absImg" alt="" /> -->
            <div class="cs-hero_text wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
                <h2 class="cs-hero_secondary_title cs-font_22 cs-font_18_sm text-uppercase">{{ getTotalSupply }} / 700 MINTED</h2>
                <h1 class="cs-hero_title text-uppercase cs-font_60 cs-font_36_sm cs-bold">A COMPLETELY NEW WAY TO MINT AND EARN WITH YOUR OWN NFTs</h1>
                <h3 class="cs-hero_subtitle cs-font_20 cs-font_16_sm cs-body_line_height">Max 4 NFTs Per Whitelist Wallet. PRICE <span class="cs-accent_color">{{ getPrice }}</span> ETH <br>
                Mint is Live Until <span class="cs-accent_color">22 March</span></h3>
                <div class="cs-btn_group">
                    <a href="#" class="cs-btn cs-btn_filed cs-accent_btn" @click="mintModal"><span>MINT NOW</span></a>
                <!-- <a href="#" class="cs-btn cs-color1"><span>Join the Waitlist</span></a> -->
                </div>
            </div>
        </div>
        <div class="cs-modal" data-modal="mint">
            <div class="cs-modal_in">
                <div class="cs-modal_container cs-white_bg">
                    <button class="cs-close_modal cs-center cs-primary_bg" @click="closeModal">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z" fill="white"/>
                        </svg>            
                    </button>
                    <div class="cs-mint_secton">
                        <h2 class="cs-font_22 text-uppercase cs-normal cs-m0 text-center">Collect your NFT before <br>the deadline.</h2>
                        <div class="cs-height_25 cs-height_lg_25"></div>
                        <div class="cs-mint_avatar text-center">
                            <img :src="require(`@/assets/img/nft/gold.png`)" alt="">
                        </div>
                        <div class="cs-height_30 cs-height_lg_20"></div>
                        <ul class="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
                            <li>
                                <div class="cs-list_left">Remaining</div>
                                <div class="cs-list_right">{{ getRemaining }} / {{ getTotalSupply }} Minted</div>
                            </li>
                            <li>
                                <div class="cs-list_left">Quantity / <span class="cs-white_color">{{ getPrice }} ETH</span></div>
                                <div class="cs-list_right">
                                    <div class="cs-quantity">
                                        <button class="cs-quantity_btn cs-center" @click="decrease">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M20 12H4"/>
                                            </svg>
                                        </button>
                                        <input type="text" value="1" v-model="counter" class="cs-quantity_number">
                                        <button class="cs-quantity_btn cs-center" @click="increase">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="cs-list_left">Total Price</div>
                                <div class="cs-list_right" style="padding-top:5px">
                                    <div>{{ getTotalPrice }} ETH</div>
                                    <div><span class="cs-yellow_color">( ${{ getTotalPriceInUSD }} )</span></div>
                                </div>
                            </li>
                        </ul>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                    <button class="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100" @click="mint"><span>Mint Now</span></button>
                    <div class="cs-height_15 cs-height_lg_15"></div>
                    <p v-if="getStatus != 1"  class="cs-m0 text-center">Presale & whitelist : Soldout</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import { BigNumber } from 'bignumber.js'

export default {
  name: "TokenPrice",
  data() {
    return {
        counter: 0,
        totalSupply: 0,
        mintAmount: 1,
    };
  },
  mounted() {
  },
  computed: {
    isMetaMaskInstalled() {
        const {ethereum} = window;
        return Boolean(ethereum && ethereum.isMetaMask)
    },
    isMetaMaskConnected() {
        return this.$store.state.account != null;
    },
    getStatus() {
        return this.$store.state.dce.status;
    },
    getMaxTokenCount() {
        return this.$store.state.dce.max_tokenCount;
    },
    getTotalSupply() {
        if(this.$store.state.dce.totalSupply > 0)
            return this.$store.state.dce.totalSupply;
        else
            return 0;
    },
    getRemaining() {
        return Number(this.$store.state.dce.max_tokenCount) - (Number(this.$store.state.dce.totalSupply))
    },
    getPrice() {
        if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2){                // pending or pubsale
            return BigNumber(this.$store.state.dce.priceInPubsale).shiftedBy(-18).toString();
        }
        else {                                                 // presale
            return BigNumber(this.$store.state.dce.priceInPresale).shiftedBy(-18).toString();   
        }
    },
    getTotalPrice() {
        if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2)                 // pending or pubsale
            return BigNumber(this.counter).times(BigNumber(this.$store.state.dce.priceInPubsale))
                .shiftedBy(-18).toString();
        else                                                                                        // presale 
            return BigNumber(this.counter).times(BigNumber(this.$store.state.dce.priceInPresale))
                .shiftedBy(-18).toString();   
    },
    getPriceInUSD() {
        if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2){                // pending or pubsale
            return BigNumber(this.$store.state.dce.priceInPubsale)
                    .times(BigNumber(this.$store.state.ethPrice))
                    .shiftedBy(-18).decimalPlaces(2, 1).toString();
        }
        else {                                                 // presale
            return BigNumber(this.$store.state.dce.priceInPresale)
                    .times(BigNumber(this.$store.state.ethPrice))
                    .shiftedBy(-18).decimalPlaces(2, 1).toString();   
        }
    },
    getTotalPriceInUSD() {
        if (this.$store.state.dce.status == 0 || this.$store.state.dce.status == 2)                 // pending or pubsale
            return BigNumber(this.counter).times(BigNumber(this.$store.state.dce.priceInPubsale))
                .times(BigNumber(this.$store.state.ethPrice))
                .shiftedBy(-18).decimalPlaces(2, 1).toString();
        else                                                                                        // presale 
            return BigNumber(this.counter).times(BigNumber(this.$store.state.dce.priceInPresale))
                .times(BigNumber(this.$store.state.ethPrice))
                .shiftedBy(-18).decimalPlaces(2, 1).toString();   
    },
  },
  methods:{
        decrease: function () {
            this.counter > 0 ? this.counter -= 1 : this.counter = 0;
        },

        increase: function () {
            this.counter < Number(this.$store.state.dce.max_tokenCount) ? 
                        this.counter += 1 : this.counter = this.$store.state.noblyWorld.max_tokenCount;
        },
        mint: function () {
            // if (!this.isMetaMaskInstalled) {
            //     this.$store.dispatch("showMessage", {
            //         message:"Please Install MetaMask!"
            //     })

            //     return;
            // }

            if (!this.isMetaMaskConnected) {
                this.$store.dispatch("showMessage", {
                    kind: 'show_error',
                    content:"Please Connect Wallet!"
                })

                return;
            }

            this.$store.dispatch('mint', {
                countOfToken:this.counter
            })
        },
        mintModal() {
            $(`[data-modal='mint']`).addClass('active')
        },
        closeModal() {
            $(`[data-modal='mint']`).removeClass('active')
        },
    },
};
</script>

<style>
    .cs-hero {
        background-image: url(../../assets/img/hero.png);
        background-size: 100%;
        /* background: gray; */
    }

    .cs-hero_img {
        width:400px;
    }

    .cs-mint_avatar img {
        width: 180px;
    }
</style>
