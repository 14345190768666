<template>
<section>
    <div class="cs-height_70 cs-height_lg_40"></div>
    <div class="container">
      <div class="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
        <h3 class="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Blog</h3>
        <h2 class="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Recent NFT News</h2>
      </div>
      <div class="cs-height_50 cs-height_lg_30"></div>
      <div class="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-blog cs-style1">
            <a href="#" class="cs-blog_thumb cs-zoom_effect"><img :src="require(`@/assets/img/blog1.jpeg`)" alt="Thumb" class="w-100"></a>
            <div class="cs-height_15 cs-height_lg_15"></div>
            <h2 class="cs-blog_title cs-font_22 cs-font_18_sm cs-m0"><a href="#">How to mint an NFT?</a></h2>
            <div class="cs-height_5 cs-height_lg_5"></div>
            <div class="cs-blgo_subtitle">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat.</div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-blog cs-style1">
            <a href="#" class="cs-blog_thumb cs-zoom_effect"><img :src="require(`@/assets/img/blog2.jpeg`)" alt="Thumb" class="w-100"></a>
            <div class="cs-height_15 cs-height_lg_15"></div>
            <h2 class="cs-blog_title cs-font_22 cs-font_18_sm cs-m0"><a href="#">What is NFTs minting?</a></h2>
            <div class="cs-height_5 cs-height_lg_5"></div>
            <div class="cs-blgo_subtitle">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat.</div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-blog cs-style1">
            <a href="#" class="cs-blog_thumb cs-zoom_effect"><img :src="require(`@/assets/img/blog3.jpeg`)" alt="Thumb" class="w-100"></a>
            <div class="cs-height_15 cs-height_lg_15"></div>
            <h2 class="cs-blog_title cs-font_22 cs-font_18_sm cs-m0"><a href="#">How can I obtain NFTs?</a></h2>
            <div class="cs-height_5 cs-height_lg_5"></div>
            <div class="cs-blgo_subtitle">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat.</div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-6">
          <div class="cs-blog cs-style1">
            <a href="#" class="cs-blog_thumb cs-zoom_effect"><img :src="require(`@/assets/img/blog4.jpeg`)" alt="Thumb" class="w-100"></a>
            <div class="cs-height_15 cs-height_lg_15"></div>
            <h2 class="cs-blog_title cs-font_22 cs-font_18_sm cs-m0"><a href="#">Benefit of Minting an NFT</a></h2>
            <div class="cs-height_5 cs-height_lg_5"></div>
            <div class="cs-blgo_subtitle">There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat.</div>
          </div>
          <div class="cs-height_25 cs-height_lg_25"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Blog",
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
