<template>
  <section id="team">
      <div class="cs-height_70 cs-height_lg_40"></div>
      <div class="container">
        <div class="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
          <h3 class="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">TEAM</h3>
          <h2 class="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Meet The Team</h2>
        </div>
        <div class="cs-height_50 cs-height_lg_30"></div>
        <div data-wow-duration="1s" data-wow-delay="0.2s">
          <hooper :settings="hooperSettings">
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/11.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Mert Eren</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">Founder & CEO</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/0xlasmgdc" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/2.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Antonio Ansari</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">CFO</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/emirhanantonio" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/3.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Lauri Ahtisaari</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">Marketing Specialist</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/lauri-ahtisaari-539ba3239" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/4.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Hakan Diyartepe</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">CMO</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/hakan-diyartepe-9baa99158" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/5.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Berat Dalgar</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">COO Co-Founder</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/hakk%C4%B1-berat-dalgar-1a96b8183" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/6.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Mustafa</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">Full Stack Developer</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/7.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Milos Gojkovic</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">Lead Blockchain Dev</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/milos-gojkovic-8b735a25a/" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/8.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Ukasha Adamu</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">Community Manager</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/ukasha-adamu-1a3665238" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="cs-team cs-style1 cs-type1 text-center">
                <div class="cs-member_thumb" :data-src="require(`@/assets/img/member_bg.jpeg`)">
                  <img :src="require(`@/assets/img/team/9.png`)" alt="Member">
                </div>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-member_info">
                  <h2 class="cs-font_22 cs-font_16_sm cs-m0">Tugberk Guncicek</h2>
                  <div class="cs-height_5 cs-height_lg_5"></div>
                  <p class="cs-m0">NFT & Content Artist</p>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <div class="cs-member_social cs-center">
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 320 512"><path fill="currentColor" d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>                   
                    </a>
                    <a href="https://www.linkedin.com/in/tu%C4%9Fberk-g%C3%BCn%C3%A7i%C3%A7ek-728828209" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1046.16 1000"><path fill="currentColor" d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"/></svg>                           
                    </a>
                    <a href="#" class="cs-primary_color cs-accent_color_hover">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clip-rule="evenodd"/></svg>                         
                    </a>
                  </div>
                </div>
              </div>
            </slide>
            <hooper-navigation v-if="!isMobile" slot="hooper-addons"></hooper-navigation>
            <hooper-pagination v-if="isMobile" slot="hooper-addons" class="teamPagination"></hooper-pagination>
          </hooper>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from "hooper";
  import "hooper/dist/hooper.css";
  
  export default {
    name: "Team",
    components: {
      Hooper,
      Slide,
      HooperNavigation,
      HooperPagination
    },
    computed: {
      isMobile() {
        return this.$q.screen.width < 768;
      },
    },
    data() {
      return {
        hooperSettingsInPc: {
            itemsToShow: 4,
            itemsToSlide: 4,
            infiniteScroll: true,
            centerMode: false,
            transition: 1200,
        },
        hooperSettingsInMobile: {
            itemsToShow: 1,
            itemsToSlide: 1,
            infiniteScroll: true,
            centerMode: false,
            transition: 1200,
        },
        hooperSettings: null
      };
    },
    created () {
    if (this.isMobile)
      this.hooperSettings = this.hooperSettingsInMobile
    else
      this.hooperSettings = this.hooperSettingsInPc
    },
    methods: {
      onMouseMove() {
        if (!this.mouseDown || this.dragging) {
          return;
        }
  
        this.dragging = true;
      },
    },
  };
  </script>
  
  <style scoped>
    .hooper {
      height:100%;
    }
  
    .hooper >>> .hooper-navigation button {
      background: #0052FF;
      margin-top:30px;
    }
  
    .hooper >>> .hooper-navigation .is-disabled {
      display: none;
    }
    .cs-member_thumb {
      /* background-image: url(../../assets/img/member_bg.jpeg); */
    }

    .teamPagination {
      margin-bottom:-50px;
    }

    .hooper >>> .hooper-pagination .hooper-indicator {
      height: 10px;
      width: 10px;
      border-radius: 58px;
      margin: 0 5px;
      cursor: pointer;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background-color: #0052FF !important;
  }
  .hooper >>> .hooper-pagination .hooper-indicators .is-active {
      width:30px;
  }

  .cs-member_thumb img {
    border-radius: 50%;
    width:155px;
    height:155px;
    border:1px solid grey;
  }
  </style>
  